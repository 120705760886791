<template>
    <v-container>
        <div class="main-div">
            <form class="form">
              <span class="form-title">Upload your file</span>
                <p class="form-paragraph">
                    File should be an csv
                </p>
                <label for="file-input" class="drop-container">
                  <span class="drop-title">Drop files here</span>
                  or
                  <input 
                    style="min-width: 500px;" 
                    type="file" 
                    @change="handleFileChange" 
                    accept=".csv" 
                    id="file-input" 
                    required=""
                  />
                </label>
                <div class="button-alignment">
                  <v-btn 
                    class="button-clip-left" 
                    @click="DownloadPDF()" 
                    :disabled="fileUploaded == false || disableViewPdfBtn" 
                    color="primary" 
                    :loading="loadingViewPdfBtn"
                  >
                    <v-icon medium class=" ml-n7">
                      mdi-eye
                    </v-icon>
                    <v-icon class="mr-1 ml-2">
                      mdi-file
                    </v-icon>
                    <span style="font-size: 8px;">View file</span>
                  </v-btn>
                  <v-btn 
                    class="button-clip-middle" 
                    @click="clearInput()" 
                    :disabled="isFileInvalid || disableUploadBtn" 
                    color="error"
                  >
                    <v-icon class="mr-2">
                      mdi-eraser
                    </v-icon>
                    <span style="font-size: 8px;">Clear Input</span>
                  </v-btn>
                  <v-btn 
                    class="button-clip-right" 
                    @click="uploadFile()" 
                    :disabled="isFileInvalid || disableUploadBtn" 
                    color="success" 
                    :loading="isUploading"
                  >
                    <v-icon class="mr-2 ml-4">
                      mdi-file-upload
                    </v-icon>
                    <span style="font-size: 8px;">Upload</span>
                  </v-btn>
                </div>
            </form>
        </div>
    </v-container>
</template>

<script>
import axios from 'axios';
export default {
  data() {
    return {
      key: false,
      file: null,
      fileUploaded: false,
      uploadMessage:null,
      isUploading: false,
      disableUploadBtn: false,
      loadingViewPdfBtn: false,
      disableViewPdfBtn: false,
    }
  },
  methods: {
    clearInput(){
      document.getElementById('file-input').value = []
      this.fileUploaded = false;
      this.file = null
    },
    handleFileChange(event) {
      this.file = event.target.files[0];
      console.log(this.file)
    },
    uploadFile() {
      this.isUploading = true
      this.disableUploadBtn = true
      setTimeout(() => {
        const formData = new FormData();
        formData.append('file', this.file);
        axios.post(`${this.aws2}uploadFile`,formData,this.apiKey2 )
          .then(res => {
              console.log(res.data)
          })
          .catch(error => {
            alert(`${error}`)
          });
      }, 2000);
      
    },
    DownloadPDF(){

    }
  },
  computed: {
    isFileInvalid() {
      if(this.file === null || this.file === undefined){
        return true
      }else{
        return false
      }
    },
  },
  mounted () {
    console.log(this.aws2);
  },
}
</script>
<style scoped>
.main-div{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}
.button-clip-middle{
  clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
  width: 33%;
}
.button-clip-right{
  clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 0% 100%);
  width: 33%;
}
.button-clip-left{
  clip-path: polygon(0 0, 100% 0%, 75% 100%, 0% 100%);
  width: 33%;
}
.button-alignment{
  display: flex;
  justify-content: space-evenly;
  padding-top: 9%;
  padding-bottom: 5%;
}
.form {
  background-color: #fff;
  box-shadow: 0 10px 60px rgb(218, 229, 255);
  border: 1px solid rgb(159, 159, 160);
  border-radius: 20px;
  padding: 2rem .7rem .7rem .7rem;
  text-align: center;
  font-size: 1.125rem;
  max-width: 720px;
}

.form-title {
  color: #000000;
  font-size: 1.8rem;
  font-weight: 500;
}

.form-paragraph {
  margin-top: 10px;
  font-size: 0.9375rem;
  color: rgb(105, 105, 105);
}

.drop-container {
  background-color: #fff;
  position: relative;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-top: 2.1875rem;
  border-radius: 10px;
  border: 2px dashed rgb(171, 202, 255);
  color: #444;
  cursor: pointer;
  transition: background .2s ease-in-out, border .2s ease-in-out;
}

.drop-container:hover {
  background: rgba(0, 140, 255, 0.164);
  border-color: rgba(17, 17, 17, 0.616);
}

.drop-container:hover .drop-title {
  color: #222;
}

.drop-title {
  color: #444;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  transition: color .2s ease-in-out;
}

#file-input {
  width: 350px;
  max-width: 100%;
  color: #444;
  padding: 2px;
  background: #fff;
  border-radius: 10px;
  border: 1px solid rgba(8, 8, 8, 0.288);
}

#file-input::file-selector-button {
  margin-right: 20px;
  border: none;
  background: #084cdf;
  padding: 10px 20px;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  transition: background .2s ease-in-out;
}

#file-input::file-selector-button:hover {
  background: #0d45a5;
}
/* input[type="file"] {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.button {
  background-color: #3e8e41 !important;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 2%;
}

.button:hover {
  background-color: #7bc67e !important;
}

.uploaded-file {
  font-size: 18px;
  font-weight: bold;
  color: #00698f;
} */
</style>